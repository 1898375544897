import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "housingfield" ]

  connect() {
  }

  changeHousing(event) {
    this.housingfieldTargets.forEach((housingfield) => {
      housingfield.value = event.target.value;
    });
  }
}